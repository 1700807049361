import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import pic1 from '../assets/cS.jpg';
import pic2 from '../assets/forOp.jpg';
import pic3 from '../assets/service.jpg';
import pic4 from '../assets/ssss.jpg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './swipe.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { NavLink } from 'react-router-dom';

export default function MySwiper() {
    return (
        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            speed={900}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            style={{ height: '600px' }} 
        >
            {[
                {
                    img: pic1,
                    text: "On the security front, we’ve upgraded our surveillance technology and expanded our training programs for security personnel.",
                },
                {
                    img: pic2,
                    text: "Streamline your hiring process with our innovative recruitment tools, ensuring you attract and retain top talent.",
                },
                {
                    img: pic3,
                    text: "HR services are crucial in guiding organizations through challenges and opportunities.",
                },
                {
                    img: pic4,
                    text: "HR services drive innovation by attracting diverse talent and cultivating an environment where creativity thrives.",
                }
            ].map((slide, index) => (
                <SwiperSlide key={index} style={{ color: 'white' }}>
                    <div className ='First-slider'>
                        <img
                            src={slide.img}
                            alt={`Slide ${index + 1}`}
                            style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                        <p 
                            className="text-animation" 
                            style={{ position: 'relative', animationDelay: `${index * 0.3}s` }} 
                        >
                            {slide.text}
                        </p>
                    
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}